import React from "react"
import { FaHome, FaUtensils, FaUserFriends } from "react-icons/fa"

export default [
  {
    icon: <FaHome />,
    title: "Gîtes",
    text:
      "Pour vos séjours et weekend, nous vous proposons des hébergements de qualité dans un lieu unique",
  },
  {
    icon: <FaUtensils />,
    title: "Table d'hôtes",
    text:
      "A la demande, notre cuisine s’appuie sur la mise en valeur des produits régionaux et du monde pour partager un bon moment de convivialité",
  },
  {
    icon: <FaUserFriends />,
    title: "Evénements",
    text:
      "Le site bénéficie d'une surface spacieuse qui permet de recevoir des groupes pour organiser séminaires, mariages et cousinades",
  },
]
