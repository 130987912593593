import React from "react"
import Gite from "../Gites/Gite"
import { useStaticQuery, graphql } from "gatsby"
import Title from "../Title"
import styles from "../../css/items.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const getGites = graphql`
  query {
    featuredGites: allContentfulGite(
      sort: { fields: position, order: ASC }
      limit: 2
    ) {
      edges {
        node {
          titre
          slug
          id: contentful_id
          topImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const FeaturedGites = () => {
  const response = useStaticQuery(getGites)
  const gites = response.featuredGites.edges
  return (
    <section className={styles.items}>
      <Title title="Découvrez" subtitle="nos gîtes" />
      <div className={styles.center}>
        {gites.map(({ node }) => {
          return <Gite key={node.id} gite={node} />
        })}
      </div>
      <AniLink fade to="/gites" className="btn-primary">
        nos gîtes
      </AniLink>
    </section>
  )
}

export default FeaturedGites
